"use client";
import React from "react";
import { useSession } from "@clerk/nextjs";
import { Frame, Layout, Loading } from "@shopify/polaris";
import { redirect } from "next/navigation";
// import router from "next/navigation";

const HomeContents = () => {
  const session = useSession();

  if (!session.isLoaded) {
    return <Loading />;
  }

  if (session.isSignedIn) {
    return redirect("/auth/signed-in/");
  }

  return redirect("/auth/sign-in");

  return null;
};

export default function Home() {
  // const r = useRouter();
  // r.push("/auth/sign-in");
  return (
    <Frame>
      <Layout>
        <div style={{ marginTop: 100 }}>
          <HomeContents />
        </div>
      </Layout>
    </Frame>
  );
}
